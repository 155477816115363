<template>
    <el-form ref="form" :model="form" :rules="rules" class="x-edit">
        <x-divider title="基本设置"/>
        <el-form-item label="图标" prop="tb" class="line">
            <x-uploader v-model="form.tb" list-type="picture-card" accept="image/jpeg,image/png" :size="1" :on-success="uploadSuccess"/>
        </el-form-item>
        <el-form-item label="打印类型" prop="daylx" class="line">
            <x-radio v-model="form.daylx" dictType="T_SZ_DYLX"/>
        </el-form-item>
        <el-form-item label="默认收款方式" prop="mrskfs" class="line">
            <x-radio v-model="form.mrskfs" dictType="T_SZ_MRSKFS"/>
        </el-form-item>
        <el-form-item label="自动抹零" prop="zdml" class="line">
            <x-switch v-model="form.zdml" dictType="T_YES_OR_NO"/>
        </el-form-item>
        <el-form-item label="自动积分抵扣" prop="zdjfdk" class="line">
            <x-switch v-model="form.zdjfdk" dictType="T_YES_OR_NO"/>
        </el-form-item>
        <el-form-item class="line">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import {add, update, getUpdate} from "@/service/szgl/JiBenSheZhi";
import {ruleBuilder} from '@/util/validate';
import {deepCopy, deepMerge} from "@/util/objects";
import * as service from "@/service/mdgl/MenDianXX";
import XUploader from "@/components/x/upload/XUploader";

export default {
    name: "JiBenSheZhiList",
    components: {XUploader},
    beforeCreate() {
        let {required} = ruleBuilder;
        this.rules = {
            tb: [required()], // 图标
            daylx: [required()], // 打印类型
            mrskfs: [required()], // 默认收款方式
            zdml: [required()], // 自动抹零
            zdjfdk: [required()], // 自动积分抵扣
            md: [], // 门店
            mdid: [], // 门店id
        }
        this.titlePrefix = '基本设置';
        this.defaultForm = {
            id: null,
            tb: [], // 图标
            daylx: "1", // 打印类型
            mrskfs: "1", // 默认收款方式
            zdml: "false", // 自动抹零
            zdjfdk: "false", // 自动积分抵扣
            md: "", // 门店
            mdid: "", // 门店id
        };
        this.addService = add;
        this.updateService = update;
        this.getUpdateService = getUpdate;
    },
    data() {
        return {
            loading: false,
            form: deepCopy(this.defaultForm),
            options: []
        }
    },
    mounted() {
        this.getConfig();
        service.checkboxList().then((response) => {
            this.options = response.data

        })
    },
    methods: {
        clearValidate() {
            this.$nextTick(() => {
                this.$refs.form.clearValidate();
            });
        },
        getConfig() {
            getUpdate().then((response) => {
                if (response.data) {
                    this.form = deepMerge(this.defaultForm, response.data);
                } else {
                    this.form = deepMerge(this.defaultForm, {});
                }
                //清空校验
                this.clearValidate();
            })
        },
        async save() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
            } catch (e) {
                this.loading = false;
                return;
            }
            let api = this.form.id ? update : add;
            api(this.form).then((response) => {
                this.$message.success(response.msg);
            }).finally(() => {
                this.loading = false;
            });
        },
        mdchange(val) {
            let item = this.options.find(o => o.id = val);
            this.form.md = item.menDianMC;
        },
        uploadSuccess(response, file, uploadFiles){
            if(this.form.tb.length>0){
                this.form.tb.shift()
            }
        }
    }
}
</script>

<style scoped>
</style>
